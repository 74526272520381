<template>
  <div class="lianluo" style="height:100%">
    <div>
      <img src="../../assets/huayang.jpg" alt="" style="width:100%">
    </div>
    <div>
      <div class="LL_title">吉利华阳企业公共服务平台</div>
      <div class="LL_title" style="font-size:20px">平台理念</div>
      <div class="LL_center">
        <p>
          吉利华阳产业集聚区，以服务产业聚集区企业全生命周期为宗旨，以公益服务为主，最大限度为园区企业获取发展所需资源和服务提供帮助和支持，进一步提升产业聚集区营商环境和场域载体。
        </p>
        <p>
          以管委会政治服务中心牵头负责，搭建“线上+线下”两个平台，引入服务类企业，以自由结合或者项目投标选择的方式，连线搭桥为企业发展做出帮助与支持。
        </p>
      </div>
      <div class="LL_tel">
        <a href="tel:0319-4861886"><button>联系我们</button></a>
      </div>
    </div>
    <!-- 底部 tab栏 -->
    <div>
      <van-tabbar v-model="active">
        <van-tabbar-item icon="home-o" to="/huayang/classroom"
          >知识大讲堂</van-tabbar-item
        >
        <van-tabbar-item icon="qr" to="/huayang/serve"
          >资源看板</van-tabbar-item
        >
        <van-tabbar-item icon="apps-o" to="/huayang">中介超市</van-tabbar-item>
        <van-tabbar-item icon="cluster-o" to="/huayang/interaction"
          >区企互动</van-tabbar-item
        >
        <van-tabbar-item icon="phone-circle-o" to="/huayang/lianluo"
          >联系我们</van-tabbar-item
        >
      </van-tabbar>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      active: 4,
    };
  },
  methods: {},
};
</script>
<style>
.LL_title {
  margin: 10px;
  margin-left: 15px;
  font-size: 22px;
}
.LL_center {
  text-indent: 2em;
  margin: 0px 15px;
  line-height: 30px;
}
.LL_tel {
  margin: 0px 15px;
}
.LL_tel a {
  color: #fff;
}
.LL_tel button {
  width: 90%;
  height: 45px;
  margin: 10px 5%;
  background: #0188fb;
  border: none;
  border-radius: 8px;
}
</style>